<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2">
              <b-form-select
                v-model="isBanned"
                track-by="value"
                :options="filterBannedOptions"
                required
                @change="asyncFind"
              >
              </b-form-select>
            </label>
            <div class="col-sm-7">
              
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="filter"
                  class="form-control"
                  placeholder="Search User"
                  @keyup.enter="asyncFind"
                />
                <b-input-group-append>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="asyncFind"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <b-col class="text-right">
              <button class="btn btn-sm btn-dark" @click="actionGetUsers">
                <i class="fa fa-refresh"></i>
              </button>
  
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
            </b-col>
          </div>
 
          <b-table
            sticky-header="500px"
            responsive="xl"
            hover
            :items="users"
            :busy="isLoading"
            :isLoading="isLoading"
            :fields="fields"
            show-empty
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template> 
            <template #cell(Email)="row">
              <div class="text-left">
                {{ row.item.users ? row.item.users.email:'' }}
              </div>
            </template>
              <template #cell(Status)="row">
              <div class="text-left">
                <button
                  v-if="row.item.is_active"
                  class="btn btn-sm btn-success"
                  @click="actionConfirm(row.item)"
                  >
                  <i class="fa fa-lock"></i>
                </button>
                <b-button size="sm" disabled v-else>
                  <em class="fa fa-unlock-alt"/>
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <svg class="heart" viewBox="0 0 32 29.6">
                  <path
                    d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                    c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                  />
                </svg>
                Loading ...
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import API from "../../plugins/http"
export default {
  name: "UserBanned",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      items_search: [],
      isBanned: null,
      filterBannedOptions: [
        { value: null, text: 'All Type' },
        { value: true, text: 'Banned' },
        { value: false, text: 'Violation' }
      ],
      fields: [
        { key:'No', label: 'No'},
        { key:'id', label: 'Banned Key'},
        { key:'Email', label: 'Email'},
        { key:'violation_status', label: 'Info'},
        { key:'expired_date'},
        { key:'Status', label: 'Status'},
      ]
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetUsers();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Account Banned | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.users.isLoading,
      isError: (state) => state.users.isError,
      totalRows: (state) => state.users.totalRowsBanned,
      users: (state) => state.users.userbanned,
    }),
  },
  mounted() {
    this.actionGetUsers();
  },
  methods: {
    ...mapActions("users", ["fetchUsersBanned","SearchUsersBanned","UnbannedUser"]),
    ...mapMutations("users", ["setItems", "setRows"]),
    actionGetUsers() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        is_active: this.isBanned,
      };
      this.fetchUsersBanned(payload);
    },
    async asyncFind() {
      if (!this.filter) return this.actionGetUsers();

      try {
        let data = {
          email: this.filter,
          is_active: this.isBanned,
        };
        let info = await this.SearchUsersBanned(data);
        console.log(info);
      } catch (error) {
        console.log(error);
      }
    },
    actionConfirm(data){
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Unbanned it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: data.id,
              user_id : data.user_id,
            }

            API.post('/user-banned/unbanned',payload)
            .then(() => {
              this.$swal({
                toast: "true",
                position: "top-end",
                icon: "success",
                title: "Sucess UnBanned",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              })
              this.actionGetUsers()
            }).catch((error)=> {
              let response = error.response
              if (response.status ==403) {
                this.$swal({
                 toast: "true",
                 position: "top-end",
                 icon: "error",
                 title: " You Do not have access to UnBanned Users",
                 showConfirmButton: false,
                 timer: 3000,
                 timerProgressBar: true,
               })
              } else {
                this.$swal({
                 toast: "true",
                 position: "top-end",
                 icon: "error",
                 title: "Failed UnBanned Pelase Contact Admin",
                 showConfirmButton: false,
                 timer: 3000,
                 timerProgressBar: true,
               })
              }
            })
          }
        });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style scoped>
</style>
